/**
 * Theme Transition Manager
 * 
 * This module handles smooth transitions between different theme colors and surfaces
 * across sections of the page. It uses GSAP's ScrollTrigger for scroll-based animations.
 * 
 * Key Features:
 * - Smooth color interpolation between themes
 * - Surface background transitions
 * - Theme-based elevation handling
 * - Interactive state preservation
 * 
 * Usage:
 * 1. Add 'scroll-animate' class to sections
 * 2. Set data-theme attribute for theme
 * 3. Set data-background for surface level
 * 4. Set data-transition-group to group related sections
 */

// Import GSAP from CDN first
let gsap, ScrollTrigger;

if (typeof window !== 'undefined') {
    gsap = window.gsap;
    ScrollTrigger = window.ScrollTrigger;
    
    if (gsap) {
        gsap.registerPlugin(ScrollTrigger);

    } else {

    }
}

class ThemeTransitionManager {
    constructor() {
        this.initialized = false;
        this.colorCache = new Map();
        
        // Verify GSAP is loaded
        if (!gsap || !ScrollTrigger) {
            return;
        }
    }

    /**
     * Updates the root theme class
     * @param {string} theme - Theme name (e.g., 'default', 'brand', 'dark')
     */
    setTheme(theme) {
        document.documentElement.className = theme ? `theme-${theme}` : '';
    }

    /**
     * Gets computed surface color for a theme
     * @param {HTMLElement} element - Target element
     * @param {string} theme - Theme name
     * @param {string} surface - Surface level (primary, secondary, tertiary)
     * @returns {string} Computed color value
     */
    getComputedSurface(element, theme, surface) {
        // Create cache key
        const cacheKey = `${theme}-${surface}`;
        
        // Check cache first
        if (this.colorCache.has(cacheKey)) {
            return this.colorCache.get(cacheKey);
        }
        
 

        const originalTheme = document.documentElement.className;
        document.documentElement.className = `theme-${theme}`;
        
        // Get all surface values for comparison
        const allSurfaces = {
            primary: getComputedStyle(document.documentElement).getPropertyValue('--surface-primary').trim(),
            secondary: getComputedStyle(document.documentElement).getPropertyValue('--surface-secondary').trim(),
            tertiary: getComputedStyle(document.documentElement).getPropertyValue('--surface-tertiary').trim()
        };

        // Get the specific surface color
        const cssVar = `--surface-${surface}`;
        const color = getComputedStyle(document.documentElement).getPropertyValue(cssVar).trim();
     
        
        document.documentElement.className = originalTheme;
        
        // Cache the result
        this.colorCache.set(cacheKey, color);
        
        return color;
    }

    /**
     * Gets all theme colors for a given theme
     * Note: When adding new theme properties:
     * 1. Add them here in the colors object
     * 2. Ensure CSS variables exist in theme tokens
     * 3. Update interpolateColors if special handling needed
     */
    getThemeColors(theme) {
        const originalTheme = document.documentElement.className;
        document.documentElement.className = `theme-${theme}`;
        
        const colors = {
            text: {
                primary: getComputedStyle(document.documentElement).getPropertyValue('--text-primary').trim(),
                secondary: getComputedStyle(document.documentElement).getPropertyValue('--text-secondary').trim(),
                tertiary: getComputedStyle(document.documentElement).getPropertyValue('--text-tertiary').trim()
            },
            interactive: {
                filled: {
                    bg: getComputedStyle(document.documentElement).getPropertyValue('--interactive-filled-bg').trim(),
                    text: getComputedStyle(document.documentElement).getPropertyValue('--interactive-filled-text').trim(),
                    hover: getComputedStyle(document.documentElement).getPropertyValue('--interactive-filled-hover').trim(),
                    active: getComputedStyle(document.documentElement).getPropertyValue('--interactive-filled-active').trim()
                },
                soft: {
                    bg: getComputedStyle(document.documentElement).getPropertyValue('--interactive-soft-bg').trim(),
                    text: getComputedStyle(document.documentElement).getPropertyValue('--interactive-soft-text').trim(),
                    hover: getComputedStyle(document.documentElement).getPropertyValue('--interactive-soft-hover').trim(),
                    active: getComputedStyle(document.documentElement).getPropertyValue('--interactive-soft-active').trim()
                },
                outline: {
                    border: getComputedStyle(document.documentElement).getPropertyValue('--interactive-outline-border').trim(),
                    text: getComputedStyle(document.documentElement).getPropertyValue('--interactive-outline-text').trim(),
                    hover: getComputedStyle(document.documentElement).getPropertyValue('--interactive-outline-hover').trim(),
                    active: getComputedStyle(document.documentElement).getPropertyValue('--interactive-outline-active').trim()
                }
            },
            stroke: {
                subtle: getComputedStyle(document.documentElement).getPropertyValue('--stroke-subtle').trim()
            },
            shadow: {
                color: getComputedStyle(document.documentElement).getPropertyValue('--shadow-color').trim(),
                strength: getComputedStyle(document.documentElement).getPropertyValue('--shadow-strength').trim(),
                highlight: getComputedStyle(document.documentElement).getPropertyValue('--shadow-highlight').trim(),
                inset: getComputedStyle(document.documentElement).getPropertyValue('--shadow-inset').trim()
            }
        };
    
        document.documentElement.className = originalTheme;
        return colors;
    }

    /**
     * Applies theme colors to an element
     * @param {HTMLElement} element - Target element
     * @param {Object} colors - Theme colors object
     */
    applyThemeColors(element, colors) {
        // Apply all color properties as CSS custom properties
        const applyNestedColors = (obj, prefix = '') => {
            Object.entries(obj).forEach(([key, value]) => {
                if (typeof value === 'object') {
                    applyNestedColors(value, `${prefix}${key}-`);
                } else {
                    // Handle elevation properties specially
                    const propertyName = prefix.startsWith('elevation') 
                        ? `--elevation-${key}` 
                        : `--${prefix}${key}`;
                    element.style.setProperty(propertyName, value);
              
                }
            });
        };

        applyNestedColors(colors);
    }

    /**
     * Initializes the transition system
     * Called once per page load
     */
    init() {
        if (this.initialized) return;
        
        const sections = document.querySelectorAll('.scroll-animate');
        
        // Set initial colors
        sections.forEach(section => {
            const theme = section.dataset.theme || 'default';
            const surface = section.dataset.background;
            
            const surfaceColor = this.getComputedSurface(section, theme, surface);
            section.style.backgroundColor = surfaceColor;
            
            const themeColors = this.getThemeColors(theme);
            this.applyThemeColors(section, themeColors);
        });

        // Group sections for transitions
        const transitionGroups = {};
        sections.forEach(section => {
            const group = section.dataset.transitionGroup;
            if (group) {
                if (!transitionGroups[group]) transitionGroups[group] = [];
                transitionGroups[group].push(section);
            }
        });

        this.setupTransitions(transitionGroups);
        this.initialized = true;
    }

    /**
     * Creates ScrollTrigger instances for each transition
     * @param {Object} transitionGroups - Grouped sections for transitions
     */
    setupTransitions(transitionGroups) {
        Object.values(transitionGroups).forEach(groupSections => {
            groupSections.forEach((section, index) => {
                if (index < groupSections.length - 1) {
                    this.createTransition(section, groupSections[index + 1]);
                }
            });
        });
    }

    /**
     * Creates a single transition between two sections
     * @param {HTMLElement} section - Current section
     * @param {HTMLElement} nextSection - Next section to transition to
     */
    createTransition(section, nextSection) {
        if (!gsap || !ScrollTrigger) {
            console.error('GSAP or ScrollTrigger not available for transition creation');
            return;
        }

        // Get initial values
        const currentTheme = section.dataset.theme || 'default';
        const nextTheme = nextSection.dataset.theme || 'default';
        const currentSurface = section.dataset.background || 'primary';
        const nextSurface = nextSection.dataset.background || 'primary';
     

        return ScrollTrigger.create({
            trigger: nextSection,
            start: "top bottom",
            end: "top 35%",
            scrub: 1,
            onEnter: () => {
                // Only update theme class, not surface
                this.setTheme(nextTheme);
            },
            onLeaveBack: () => {
                // Only update theme class, not surface
                this.setTheme(currentTheme);
            },
            onUpdate: (self) => {
                const progress = self.progress;
                
                // Get current theme's surface color
                const currentColor = this.getComputedSurface(
                    section, 
                    currentTheme, 
                    currentSurface // Use section's own surface level
                );
                
                // Get next theme's surface color
                const nextColor = this.getComputedSurface(
                    nextSection, 
                    nextTheme, 
                    nextSurface // Use next section's own surface level
                );
                
                const interpolatedSurface = gsap.utils.interpolate(
                    currentColor,
                    nextColor,
                    progress
                );
                
                // Apply interpolated surface colors
                section.style.backgroundColor = interpolatedSurface;
                nextSection.style.backgroundColor = interpolatedSurface;
                
                // Handle theme colors (keeping surface levels separate)
                const currentThemeColors = this.getThemeColors(currentTheme);
                const nextThemeColors = this.getThemeColors(nextTheme);
                
                const interpolatedColors = this.interpolateColors(
                    currentThemeColors,
                    nextThemeColors,
                    progress
                );
                
                this.applyThemeColors(section, interpolatedColors);
                this.applyThemeColors(nextSection, interpolatedColors);
            }
        });
    }

    /**
     * Interpolates between two color objects
     * @param {Object} current - Current theme colors
     * @param {Object} next - Next theme colors
     * @param {number} progress - Transition progress (0-1)
     */
    interpolateColors(current, next, progress) {
        const result = {};
        
        Object.keys(current).forEach(key => {
            if (typeof current[key] === 'object') {
                result[key] = this.interpolateColors(current[key], next[key], progress);
            } else {
                result[key] = gsap.utils.interpolate(current[key], next[key], progress);
            }
        });
        
        return result;
    }
}

// Initialize when GSAP is available
const initializeThemeTransition = () => {
    if (window.gsap && window.ScrollTrigger) {
        window.themeTransition = new ThemeTransitionManager();
    } else {
        console.log('Waiting for GSAP...');
        setTimeout(initializeThemeTransition, 50);
    }
};

if (typeof window !== 'undefined') {
    initializeThemeTransition();
}

export const themeTransition = {
  init() {
    // ... existing initialization code
  }
  // ... rest of the implementation
};